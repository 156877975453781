import { forwardRef } from 'react';

import { Box, CircularProgress, InputAdornment, Theme, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

import { Search as SearchIcon } from 'react-feather';
import Button from '~/components/atoms/Button';
import TextField from '~/components/atoms/TextField';
import TextFieldProps from '~/components/atoms/TextField/types';

import { mergeSx } from '~/utils/mui';

interface SearchInputProps extends TextFieldProps {
    inputState: 'loading' | 'idle' | 'submitting';
    size?: 'small' | 'medium' | 'large';
    onReset?: () => void;
    onEnter?: () => void;
    value?: string;
    wrapperSx?: SxProps<Theme>;
}

const SearchInput = forwardRef<unknown, SearchInputProps>(
    ({ onChange, value, inputState, size, onKeyPress, onReset, onEnter, wrapperSx, sx, inputProps }, ref) => {
        const theme = useTheme();

        return (
            <Box sx={mergeSx({ position: 'relative' }, wrapperSx)}>
                {onReset && inputState === 'idle' && value && value.length > 0 && (
                    <Button
                        variant="transparent"
                        sx={{
                            zIndex: 10,
                            position: 'absolute',
                            right: size === 'large' ? '10px' : size === 'medium' ? '8px' : '4px',
                            top: size === 'large' ? '10px' : size === 'medium' ? '8px' : '4px',
                        }}
                        onClick={onReset}
                        data-cy="cy-search-nullstill"
                        size={size || 'small'}
                    >
                        Nullstill
                    </Button>
                )}
                {onEnter && inputState === 'idle' && value && value.length > 0 && (
                    <Button
                        variant="primary"
                        sx={{
                            zIndex: 10,
                            position: 'absolute',
                            '&.MuiButton-sizeLarge': {
                                fontSize: size === 'large' ? theme.typography.h5.fontSize : 'inherit',
                            },
                            right: size === 'large' ? '8px' : size === 'medium' ? '8px' : '4px',
                            top: size === 'large' ? '8px' : size === 'medium' ? '8px' : '4px',
                        }}
                        onClick={onEnter}
                        data-cy="cy-search-enter"
                        size={size || 'small'}
                    >
                        Søk
                    </Button>
                )}
                <TextField
                    ref={ref}
                    onKeyPress={onKeyPress}
                    inputProps={{
                        'aria-label': 'Søketekst',
                        id: 'searchInput',
                        ...inputProps,
                    }}
                    onChange={onChange}
                    size={size}
                    fullWidth
                    value={value}
                    sx={mergeSx(
                        {
                            zIndex: 0,
                            pl: size === 'large' ? 6 : 4,
                        },
                        sx,
                    )}
                    startAdornment={
                        <InputAdornment position="start" sx={{ mr: size === 'large' ? 4 : 3 }}>
                            <SearchIcon
                                color={
                                    inputProps && inputProps.disabled
                                        ? theme.palette.text.disabled
                                        : theme.palette.mode === 'light'
                                          ? theme.palette.cta.main
                                          : theme.palette.common.white
                                }
                                size={size === 'large' ? 26 : size === 'medium' ? 24 : 20}
                            />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            {inputState !== 'idle' && (
                                <CircularProgress
                                    size={size === 'large' ? 22 : size === 'small' ? 16 : 18}
                                    sx={{
                                        color:
                                            theme.palette.mode === 'light'
                                                ? theme.palette.cta.main
                                                : theme.palette.common.white,
                                    }}
                                />
                            )}
                        </InputAdornment>
                    }
                />
            </Box>
        );
    },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
